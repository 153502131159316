/* css */
html {
  scroll-behavior: smooth;
}

/**SIGNIN START**/
/* @import url("https://fonts.googleapis.com/css?family=Lato:300,400|Poppins:300,400,800&display=swap"); */

.sign-in-box {
  width: 100%;
  height: 100vh;
  /* background: #1b1c1e; */
  background: #003091c7;
  /* background:linear-gradient(141deg, #ccc 25%, #eee 40%, #ddd 55%); */
  display: flex;
  justify-content: center;
  align-items: center;
}

.sign-in-box .box {
  width: 250px;
  height: 100px;
  position: relative;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.sign-in-box .box .title {
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  height: 50px;
}

.sign-in-box .box .title .block {
  width: 0%;
  height: inherit;
  background: #ffb510;
  position: absolute;
  animation: mainBlock 1s cubic-bezier(0.74, 0.06, 0.4, 0.92) forwards;
  display: flex;
}

.sign-in-box .box .title h1 {
  font-family: "Poppins";
  color: #fff;

  /* color:#555; */
  font-size: 32px;
  -webkit-animation: mainFadeIn 1s forwards;
  -o-animation: mainFadeIn 1s forwards;
  animation: mainFadeIn 1s forwards;
  animation-delay: 0.6s;
  opacity: 0;
  display: flex;
  align-items: baseline;
  position: relative;
  letter-spacing: 1px;
}

.sign-in-box .box .title h1 span {
  width: 0px;
  height: 0px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  background: #ffb510;
  -webkit-animation: load 0.6s cubic-bezier(0.74, 0.06, 0.4, 0.92) forwards;
  animation: popIn 0.8s cubic-bezier(0.74, 0.06, 0.4, 0.92) forwards;
  animation-delay: 1s;
  margin-left: 5px;
  margin-top: -10px;
  position: absolute;
  bottom: 13px;
  right: -12px;
}

.sign-in-box .box .role {
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  height: 30px;
  margin-top: -10px;
}

.sign-in-box .box .role .block {
  width: 0%;
  height: inherit;
  background: #e91e63;
  position: absolute;
  animation: secBlock 1s cubic-bezier(0.74, 0.06, 0.4, 0.92) forwards;
  animation-delay: 1s;
  display: flex;
}

.sign-in-box .box .role h2 {
  animation: secFadeIn 1s forwards;
  animation-delay: 1s;
  opacity: 0;
  font-family: "Lato";
  /* color: #fff; */
  color: #000000;
  font-weight: bolder;
  /* font-size: 15px; */
  font-size: 18px;
  text-transform: uppercase;
  letter-spacing: 5px;
  cursor: pointer;
}

.sign-in-box .box .role .hover-underline-animation {
  display: inline-block;
  position: relative;
  /* color: #00aaff; */
  color: #ffb510;
}

.sign-in-box .box .role .hover-underline-animation::after {
  content: "";
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 2px;
  bottom: -3px;
  left: 0;
  background-color: #ffb510;
  /* background-color: #0087ca; */
  transform-origin: bottom right;
  transition: transform 0.25s ease-out;
}

.sign-in-box .box .role .hover-underline-animation:hover::after {
  transform: scaleX(1);
  transform-origin: bottom left;
}

@keyframes mainBlock {
  0% {
    width: 0%;
    left: 0;
  }

  50% {
    width: 100%;
    left: 0;
  }

  100% {
    width: 0;
    left: 100%;
  }
}

@keyframes secBlock {
  0% {
    width: 0%;
    left: 0;
  }

  50% {
    width: 100%;
    left: 0;
  }

  100% {
    width: 0;
    left: 100%;
  }
}

@keyframes mainFadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes popIn {
  0% {
    width: 0px;
    height: 0px;
    background: #e9d856;
    border: 0px solid #ddd;
    opacity: 0;
  }

  50% {
    width: 10px;
    height: 10px;
    background: #e9d856;
    opacity: 1;
    bottom: 45px;
  }

  65% {
    width: 7px;
    height: 7px;
    bottom: 0px;
    width: 15px;
  }

  80% {
    width: 10px;
    height: 10px;
    bottom: 20px;
  }

  100% {
    width: 7px;
    height: 7px;
    background: #e9d856;
    border: 0px solid #222;
    bottom: 13px;
  }
}

@keyframes secFadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 0.9;
  }
}

/**SIGNIN END**/
/***NEW CSS START***/

.ant-dropdown-menu {
  top: 0px !important;
  padding: 5px 2px !important;
  right: 20px !important;
}

.ant-dropdown-menu .anticon svg {
  margin: -5px 0 0 0;
}

.ant-badge-count {
  top: 20px !important;
  right: 11.5px !important;
}

.ant-badge-dot {
  top: 25px !important;
  right: 17px !important;
  width: 8px !important;
  min-width: 8px !important;
  height: 8px !important;
}

.site-layout {
  background: #f2f2f3 !important;
  letter-spacing: 0.49px !important;
}

.ant-layout-sider-light {
  top: 0px;
  left: 0px;
  z-index: 111;
  /* height: 100vh; */
  /* position: fixed !important; */
  overflow: hidden !important;
  background-size: cover !important;
  background-position: center top !important;
  box-shadow: rgb(0 0 0 6%) 0px 5px 5px -3px, rgb(0 0 0  4%) 0px 8px 10px 1px,
    rgb(0 0 0  4%) 0px 3px 14px 2px !important;
  background-repeat: no-repeat !important;
  color: rgb(255, 255, 255) !important;
  transition: all 250ms ease-in-out 0s !important;
  background-image: linear-gradient(
      rgba(34, 42, 69, 0.96),
      rgba(34, 42, 69, 0.96)
    ),
   
}

.ant-menu {
  background: transparent !important;
  color: rgb(255, 255, 255) !important;
  overflow-y: auto !important;
  height: 100vh !important;
}

.ant-menu-item-selected  {
  color: #3D5170 !important;
  background: none !important; 
}



.ant-menu-item-selected a{
  color: #3d5170 !important;
}
.ant-menu-item-selected a:hover,
.ant-menu-item-selected a::after
 {
  background: none !important;
  color: #007bff !important;
}


.ant-menu-item-active a,
.ant-menu-item-active a:hover
{
  background: none !important;
  color: #007bff !important;
}
.ant-menu-item:hover,
.ant-menu-item-active,
.ant-menu-item::after,
.ant-menu-submenu-open,
.ant-menu-submenu-active,
.ant-menu-submenu-selected,
.ant-menu-submenu-arrow,
.ant-menu-submenu-title:hover {
  color:#007bff !important;
  /* background: rgba(255, 255, 255, 0.08) !important; */
}

.ant-menu-inline .ant-menu-item-selected::after {
  border-right: none !important;
}


.ant-menu-item-selected, .ant-menu-item-selected a, .ant-menu-item-selected a:hover {
  color: #1890ff !important;
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  
  color: #007bff !important;
}

.ant-menu-inline,
.ant-menu-vertical {
  border-right: none !important;
}

.sider-links{
  color: #3D5170 !important;
  text-decoration: none;
}

.sider-links:hover {
  color: #3D5170 !important;
  text-decoration: none;
}


.ant-menu-item a {
  color: #3D5170 !important;
}


.ant-menu-inline.ant-menu-root .ant-menu-item,
.ant-menu-inline.ant-menu-root .ant-menu-submenu-title {
  /* letter-spacing: 1.5px !important; */
  margin: 0 0 0 15px !important;
  width: 227.5px !important;
  /* display: flex;
  overflow: hidden; */
  border-radius: 4px !important;
  height: 44px !important;
  white-space: pre !important;
  margin-bottom: 8px !important;
  text-decoration: none !important;
  -webkit-box-pack: justify !important;
  justify-content: space-between !important;
  transition: all 150ms ease-in 0s !important;
  color: #7a7a7a !important;
}



.ant-menu-inline.ant-menu-root .ant-menu-item:hover,
.ant-menu-inline.ant-menu-root .ant-menu-submenu-title {
  /* letter-spacing: 1.5px !important; */
  margin: 0 0 0 15px !important;
  width: 227.5px !important;
  /* display: flex;
  overflow: hidden; */
  border-radius: 4px !important;
  height: 44px !important;
  white-space: pre !important;
  margin-bottom: 8px !important;
  text-decoration: none !important;
  -webkit-box-pack: justify !important;
  justify-content: space-between !important;
  transition: all 150ms ease-in 0s !important;
  color: #007bff !important;
}

.brand-name {
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  justify-content: space-between;
  padding: 15px 18px;
  margin-right: -18.5px;
  /* margin-bottom: 14px; */
}

.brand-logo {
  gap: 8px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
}

.app-name {
  line-height: 1.5;
  text-transform: none;
  white-space: normal;
  font-size: 18px;
  display: block;
  color: #3d5170  !important;
}

/* .admin-dash .anticon svg {
  font-size: 16px !important;
} */
.admin-dash .sider-menu .anticon svg {
  margin: 0 0px 0 -15px !important;
  font-size: 16px !important;
}

.menu-subhead {
  white-space: normal;
  font-size: 12.5px;
  margin-top: 25px;
  margin-left: 30px;
  margin-bottom: 15px;
  text-transform: uppercase;
  font-weight: bold !important;
  color: #3d5170 !important;
}

.trigger {
  transition: 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms !important;
  color: #c3c7cc !important;
  padding: 15px;
  margin: 0 0 0 10px;
  font-size: 18px;
  /* line-height: 64px; */
  cursor: pointer;
}

.trigger:hover {
  background-color: rgba(0, 0, 0, 0.04);
  color: #c3c7cc !important;
  border-radius: 50% !important;
  overflow: visible !important;
}

.bell {
  transition: 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms !important;
  color: #c3c7cc !important;
  padding: 15px;
  margin: 0 0 0 5px;
  font-size: 18px;
  /* line-height: 64px; */
  cursor: pointer;
}

.bell:hover {
  background-color: rgba(0, 0, 0, 0.04);
  color: #c3c7cc !important;
  border-radius: 50% !important;
  overflow: visible !important;
}

.search {
  transition: 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms !important;
  color: rgba(52, 49, 76, 1) !important;
  padding: 15px;
  margin: 0 0 0 5px;
  font-size: 18px;
  line-height: 64px;
  cursor: pointer;
}

.search:hover {
  background-color: rgba(0, 0, 0, 0.04);
  color: rgba(52, 49, 76, 1) !important;
  border-radius: 50% !important;
  overflow: visible !important;
}

.admin-dash Header {
  top: 0;
  z-index: 96;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  box-shadow: 0px 5px 5px -3px rgb(0 0 0 / 6%), 0px 8px 10px 1px rgb(0 0 0 / 4%),
    0px 3px 14px 2px rgb(0 0 0 / 4%) !important;
  height: 64px;
  position: sticky !important;
}

.dropdown-toggle::after {
  display: none !important;
}

.avatar-user-details {
  border-radius: 24px !important;
  padding: 4px !important;
  /* display: inline-block !important; */
  color: #34314c !important;
  float: right !important;
  margin: 10px 0 0 0;
}

.avatar-user-details:hover {
  background-color: rgba(0, 0, 0, 0.04) !important;
}

.avatar-box {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  cursor: pointer;
}

.avatar-box span {
  margin: 0 6px;
}

.avatar-box .anticon svg {
  margin: -6px 0 0 0;
  font-size: 22px !important;
}

.avatar-name {
  line-height: 1.5;
  text-transform: none;
  white-space: normal;
}

.avatar-img {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  /* width: 40px;
  height: 40px; */
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 1.25rem;
  /* line-height: 1; */
  border-radius: 50%;
  overflow: hidden;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: pointer;
}

.icon-search {
  float: right !important;
}



.count-cards .details .anticon svg {
  /* opacity: 0.6 !important; */
  font-size: 44px !important;
  color: #3d5170 !important;
  display: inline-flex !important;
}

.count-cards .buttons .anticon svg {
  opacity: 0.6 !important;
  font-size: large;
  margin: -4px 0 0 0;
}

.count-cards .card {
  transition: 0px 3px 3px -2px rgb(0 0 0 / 6%), 0px 3px 4px 0px rgb(0 0 0 / 4%),
    0px 1px 8px 0px rgb(0 0 0 / 4%) 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  overflow: hidden !important;
  border-radius: 8px !important;
  box-shadow: 0px 3px 3px -2px rgb(0 0 0 / 6%), 0px 3px 4px 0px rgb(0 0 0 / 4%),
    0px 1px 8px 0px rgb(0 0 0 / 4%) !important;
  display: flex !important;
}

.count-cards .card:hover {
  box-shadow: 0px 0px 0px 0px rgb(0 0 0 / 2%), 0px 1px 8px 0px rgb(0 0 0 / 22%) !important;
  transition: 0.3s linear all;
}

.dash-chart-count .card {
  transition: 0px 3px 3px -2px rgb(0 0 0 / 6%), 0px 3px 4px 0px rgb(0 0 0 / 4%),
    0px 1px 8px 0px rgb(0 0 0 / 4%) 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  overflow: hidden !important;
  border-radius: 8px !important;
  box-shadow: 0px 3px 3px -2px rgb(0 0 0 / 6%), 0px 3px 4px 0px rgb(0 0 0 / 4%),
    0px 1px 8px 0px rgb(0 0 0 / 4%) !important;
  display: flex !important;
}
.count-cards .card-body2 {
  transition: 0px 3px 3px -2px rgb(0 0 0 / 6%), 0px 3px 4px 0px rgb(0 0 0 / 4%),
    0px 1px 8px 0px rgb(0 0 0 / 4%) 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  overflow: hidden !important;
  border-radius: 8px !important; /* box-shadow: 0px 3px 3px -2px rgb(0 0 0 / 6%), 0px 3px 4px 0px rgb(0 0 0 / 4%),  0px 1px 8px 0px rgb(0 0 0 / 4%) !important; */
  display: flex !important;
  /* border-style: solid; */
  /* border-color: #b2b9be; */
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -webkit-box-pack: justify !important;
  -webkit-justify-content: space-between !important;
  justify-content: space-between !important; /* padding: 15px !important; */
  padding: 24px 10px !important;
  box-shadow: 0px 3px 3px -2px rgb(0 0 0 / 28%), 0px 3px 4px 0px rgb(0 0 0 / 30%),
  0px 1px 8px 0px rgb(0 0 0 / 4%) !important;
  /* background-image: linear-gradient(
    45deg,
    var(--cui-danger-start, #ec89a7 0),
    var(--cui-danger-stop, #d73263 100%)
  ); */
  background-image: linear-gradient(
    45deg,
    /* var(--cui-primary-start, #f8f8ff 0),
    var(--cui-primary-stop, #b5b5d7 100%) */
    var(--cui-primary-start, #f5f5f5 0),
    var(--cui-primary-stop, #ded5ca 100%)
  );
  
  /* height: 80px !important; */
}
.count-cards .card-body3 {
  transition: 0px 3px 3px -2px rgb(0 0 0 / 6%), 0px 3px 4px 0px rgb(0 0 0 / 4%),
    0px 1px 8px 0px rgb(0 0 0 / 4%) 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  overflow: hidden !important;
  border-radius: 8px !important; /* box-shadow: 0px 3px 3px -2px rgb(0 0 0 / 6%), 0px 3px 4px 0px rgb(0 0 0 / 4%),  0px 1px 8px 0px rgb(0 0 0 / 4%) !important; */
  display: flex !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -webkit-box-pack: justify !important;
  -webkit-justify-content: space-between !important;
  justify-content: space-between !important; /* padding: 15px !important; */
  padding: 24px 10px !important;
  /* box-shadow: 0px 3px 3px -2px rgb(0 0 0 / 6%), 0px 3px 4px 0px rgb(0 0 0 / 4%),
  0px 1px 8px 0px rgb(0 0 0 / 4%) !important; */
  box-shadow: 0px 3px 3px -2px rgb(0 0 0 / 28%), 0px 3px 4px 0px rgb(0 0 0 / 30%),
  0px 1px 8px 0px rgb(0 0 0 / 4%) !important;
  /* background-image: linear-gradient(
    45deg,
    var(--cui-danger-start, #ec89a7 0),
    var(--cui-danger-stop, #d73263 100%)
  ); */
  background-image: linear-gradient(
    45deg,
    var(--cui-primary-start,#f5f5f5  0),
    var(--cui-primary-stop,#d3cccc  100%)
  );
  /* height: 80px !important; */
  /* border-style: solid; */
  /* border-color: #999fa4; */
}
.count-cards .card-body4 {
  transition: 0px 3px 3px -2px rgb(0 0 0 / 6%), 0px 3px 4px 0px rgb(0 0 0 / 4%),
    0px 1px 8px 0px rgb(0 0 0 / 4%) 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  overflow: hidden !important;
  border-radius: 8px !important; /* box-shadow: 0px 3px 3px -2px rgb(0 0 0 / 6%), 0px 3px 4px 0px rgb(0 0 0 / 4%),  0px 1px 8px 0px rgb(0 0 0 / 4%) !important; */
  display: flex !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -webkit-box-pack: justify !important;
  -webkit-justify-content: space-between !important;
  justify-content: space-between !important; /* padding: 15px !important; */
  padding: 24px 10px !important;
  box-shadow: 0px 3px 3px -2px rgb(0 0 0 / 28%), 0px 3px 4px 0px rgb(0 0 0 / 30%),
  0px 1px 8px 0px rgb(0 0 0 / 4%) !important;
  /* background-image: linear-gradient(
    45deg,
    var(--cui-danger-start, #ec89a7 0),
    var(--cui-danger-stop, #d73263 100%)
  ); */
  background-image: linear-gradient(
    45deg,
    var(--cui-primary-start, #f2f3f4  0),
    var(--cui-primary-stop, #dae2eb  100%)
  );
  /* border-style: solid; */
  /* border-color: #999fa4; */
  /* height: 80px !important; */
}
.count-cards .card-body {
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -webkit-box-pack: justify !important;
  -webkit-justify-content: space-between !important;
  justify-content: space-between !important;
  /* padding: 15px !important; */
  padding: 24px 15px !important;
  background: #fff !important;
  /* height: 80px !important; */
}

.count-cards .count .name-cloud {
  color: #3d5170 !important;
  font-size: 13px !important;
  font-weight: 550 !important;
  line-height: 1.5 !important;
  text-transform: none !important;
  white-space: normal !important;
}

.count-cards .count h6 {
  margin: 0 !important;
  margin-top: 4px !important;
  font-size: 14px !important;
  font-weight: 550 !important;
  color: #1976d2 !important;
}

.count-cards .card .buttons {
  float: right !important;
  margin: 4px 0 0 0 !important;
  /* padding: 0px !important; */
}

.count-cards .card .buttons .ant-btn-lg {
  padding: 6.4px 10px !important;
}

.count-cards .card .buttons:hover {
  background-color: rgba(0, 0, 0, 0.04);
  color: rgba(52, 49, 76, 1) !important;
  border-radius: 50% !important;
  overflow: visible !important;
}

/* .dash-chart-count .chart-head {
  font-size: 1rem !important;
  font-weight: 500 !important;
  margin-right: 0.5rem !important;
  text-transform: capitalize !important;
}
.dash-chart-count .chart-subhead {
  font-size: 0.875rem !important;
  color: rgba(52, 49, 76, 0.54) !important;
} */
.list-accounts .card {
  padding: 0 0 10px 0 !important;
  transition: 0px 3px 3px -2px rgb(0 0 0 / 6%), 0px 3px 4px 0px rgb(0 0 0 / 4%),
    0px 1px 8px 0px rgb(0 0 0 / 4%) 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  /* overflow: hidden !important; */
  border-radius: 8px !important;
  box-shadow: 0px 3px 3px -2px rgb(0 0 0 / 6%), 0px 3px 4px 0px rgb(0 0 0 / 4%),
    0px 1px 8px 0px rgb(0 0 0 / 4%) !important;
}

.list-accounts .card:hover {
  box-shadow: 0px 0px 0px 0px rgb(0 0 0 / 2%), 0px 1px 8px 0px rgb(0 0 0 / 22%) !important;
  transition: 0.3s linear all;
}

.ant-carousel .slick-dots li.slick-active button {
  background: #232a44 !important;
}

.ant-carousel .slick-dots li button {
  background: #464c62 !important;
}

.ant-table-row:hover {
  background-color: rgba(0, 0, 0, 0.04) !important;
}

.menu-position {
  position: fixed !important;
}

.ant-layout-footer {
  background-color: rgb(255, 255, 255) !important;
  color: rgb(34, 42, 69) !important;
  box-shadow: rgb(0 0 0 / 6%) 0px 5px 5px -3px,
  rgb(0 0 0 / 4%) 0px 8px 10px 1px, rgb(0 0 0 / 4%) 0px 3px 14px 2px !important;
}

.ant-dropdown-menu-item:hover {
  margin: 0 5px 0 5px !important;
  border-radius: 5px !important;
}

.container-dash-g {
  overflow: hidden auto !important;
  height: 440px !important; 
  margin: -20px -20px -32px -20px !important;
}
.details {
  display: inline-flex !important;
}
.badge-div{
  position: absolute !important;
  top:0 !important;
  right: 0 !important;
  
}
.badge{
  position: relative !important;
}


.name-g {
  font-size: 1.5rem;
  font-weight: bolder;
  text-transform: capitalize;
  margin-bottom: 2px;
  color: rgba(255, 255, 255, 0.87);
}

.graph-div {
  overflow: hidden;
  height: 260px;
  margin: 0;
}

.dash-graph-line {
  padding: 28px 32px 56px !important;
  /* padding: 28px 32px 86px !important; */
  background: #fafafa !important;
}

.div-dash {
  position: relative;
  /* top: 15px; */
  top:-10px;
}

.div-dash2{
  position: relative;
  top: 20px;

}

/*COLLAPSED TOOGLE START*/
.display-switch,
.collapsed-switch-visible {
  display: none;
}

.ant-switch-checked {
  background-color: rgba(0, 0, 0, 0.25) !important;
}

.ant-switch-checked:focus {
  box-shadow: none !important;
}

.ant-switch-handle:before {
  background-color: #ff9e43 !important;
}

.toogle-off {
  z-index: 9999 !important;
  top: 20px !important;
  position: fixed !important;
  left: 211px !important;
}

.toogle-on {
  z-index: 9999 !important;
  top: 5px !important;
  position: fixed !important;
  left: 23px !important;
}

.ant-layout-sider-collapsed .app-name {
  display: none !important;
}

.ant-layout-sider-children .ant-menu.ant-menu-inline-collapsed {
  width: 80px !important;
}

.ant-layout-sider-collapsed .brand-name {
  margin: 0px 0 0 0px;
}

.ant-layout-sider-collapsed .menu-subhead {
  white-space: normal;
  font-size: 12.5px;
  margin-top: 25px;
  margin-left: 10px;
  margin-bottom: 15px;
  text-transform: uppercase;
  color: rgba(255, 255, 255, 0.7);
}

.ant-menu.ant-menu-inline-collapsed > .ant-menu-item .ant-menu-item-icon,
.ant-menu.ant-menu-inline-collapsed > .ant-menu-item .anticon {
  margin: 0 0 0 5px !important;
  
  color: #000000 !important;
  
}

/*COLLAPSED TOOGLE END*/
/***NEW CSS ENd***/

.bg-image-home {
  /* background-image: url("./assests/img/young-people-in-office.jpg") !important; */
  height: 100vh;
}

.bg-image-home .card {
  border: 0px;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  letter-spacing: 0.5px;
  border-radius: 8px;
  -webkit-box-shadow: 0px 0px 20px 20px rgb(0 0 0 / 2%),
    5px 5px 20px 0px rgb(0 0 0 / 22%);
  box-shadow: 0px 0px 20px 20px rgb(0 0 0 / 2%),
    5px 5px 20px 0px rgb(0 0 0 / 22%);
}

.bg-image-home .card:hover {
  box-shadow: 4px 4px 20px #dadada;
  transition: 0.3s linear all;
}

.home .anticon svg {
  color: #0060aa !important;
}

.home {
  width: 50% !important;
  margin: 6% auto;
}

.navbar {
  background-color: #0060aa !important;
}

.navbar-nav {
  margin: 0 0 0 auto !important;
}

/*************************ADMIN DASHBOARD*************************************/
.statistics-card .card {
  /* border: 0px; */
  /* -webkit-transition: all 0.3s ease; */
  /* transition: all 0.3s ease;
  letter-spacing: 0.5px; */
  transition: 0px 3px 3px -2px rgb(0 0 0 / 6%), 0px 3px 4px 0px rgb(0 0 0 / 4%),
    0px 1px 8px 0px rgb(0 0 0 / 4%) 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  /* overflow: hidden !important; */
  border-radius: 8px !important;
  box-shadow: 0px 3px 3px -2px rgb(0 0 0 / 6%), 0px 3px 4px 0px rgb(0 0 0 / 4%),
    0px 1px 8px 0px rgb(0 0 0 / 4%) !important;
}

/* .statistics-card .card:hover {
  box-shadow: 0px 0px 0px 0px rgb(0 0 0 / 2%), 0px 1px 8px 0px rgb(0 0 0 / 22%) !important;
  transition: 0.3s linear all;
} */
.statistics-card .cloud-charts h4 {
  letter-spacing: 2px;
}

.sign-out-link a {
  color: #014d87 !important;
  letter-spacing: 1.2px;
  float: right;
  margin: 0 20px 0 0;
}

.sign-out-link a:hover {
  color: #014d87 !important;
}

/* .site-layout {
  background: #f6f7fc !important;
} */
.admin-dash {
  /* font-family: "Poppins"; */
  /* font-family: Roboto, Helvetica, Arial, sans-serif !important; */
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Helvetica Neue, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji,
    Segoe UI Symbol, Noto Color Emoji !important;
}

/* .trigger {
  padding: 0 24px;
  font-size: 18px;
  line-height: 64px;
  cursor: pointer;
  transition: color 0.3s;
  color: #0060aa !important;
}

.trigger:hover {
  color: #014d87 !important;
} */
.add-button {
  float: right !important;
}

.pagination-div {
  float: right;
}

/************SIDER************/
.ant-layout-sider {
  box-shadow: 0px 0px 0px 0px rgb(0 0 0 / 2%),
    -1px 1px 8px 0px rgba(0, 0, 0, 0.22);
  /* height: 100vh; */
}

/* .admin-dash Header {
  box-shadow: 0px 0px 0px 0px rgb(0 0 0 / 2%), 0px 1px 8px 0px rgb(0 0 0 / 22%);
} */

.logo-admin {
  height: 32px;
  /* margin: 16px; */
  margin: -50px 0px 150px 0;
  background: rgba(255, 255, 255, 0.3);
}

.site-layout .site-layout-background {
  background: #1976d2;
}

/* .anticon svg {
  color: white;
} */

/* .ant-menu-item-selected {
  color: #0060aa !important;
} */
/* .ant-menu-inline .ant-menu-item-selected::after {
  border-right: 3px solid #0060aa !important;
} */
/* .ant-menu-inline.ant-menu-root .ant-menu-item,
.ant-menu-inline.ant-menu-root .ant-menu-submenu-title {
  letter-spacing: 1.5px !important;
} */
/* .ant-menu-item:hover,
.ant-menu-item-active,
.ant-menu-submenu-open,
.ant-menu-submenu-active,
.ant-menu-submenu-selected,
.ant-menu-submenu-arrow,
.ant-menu-submenu-title:hover {
  color: #0060aa !important;
} */
/* .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected { */
/* background-color: #e8f0fe !important; */
/* background-color: #afe7fc !important; */
/* } */
/* .ant-dropdown-menu-item:hover {
  background-color: #afe7fc !important;
  color: #0060aa !important;
} */
/* .ant-menu-item-selected a,
.ant-menu-item-selected a:hover {
  color: #0060aa !important;
} */
/* .sider-links:hover {
  color: #0060aa !important;
  text-decoration: none;
} */
.profile-section-sider {
  text-align: center;
}

.profile-name-sider {
  margin: 15px 0 0 0;
  font-size: 7.5px;
  font-weight: bold;
  color: #0060aa;
  text-transform: uppercase;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  letter-spacing: 1px !important;
}

.profile-email-sider {
  font-size: 12px;
  margin: 10px 10px 25px 10px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.avatar .anticon svg {
  margin: -10px 0 0 0 !important;
}

/************HEADING + BREADCrums************/
.ant-breadcrumb a {
  color: #ad49ff !important;
}

.ant-breadcrumb a:hover {
  color: #9b2af7 !important;
}

.ant-breadcrumb-link {
  cursor:default;
  letter-spacing: 1px !important;
  color: #3D5170;
 

 
}
.ant-breadcrumb-link label{
  cursor: pointer;
}

.pages-header {
  font-size: 24px;
  margin-bottom: 0;
  font-weight: 350;
  /* text-transform: uppercase; */
  letter-spacing: 2px;
}

.ant-breadcrumb {
  float: right;
  margin: 5px 0 0 0;
  margin-top: 5px !important;
  margin-left: 5px !important;
}

.title-row .anticon svg {
  margin: -45% 0 0 0;
}

/************CARD************/
.order-card {
  color: #fff;
}

.bg-c-blue {
  background: linear-gradient(45deg, #002643, #8ae0ff);
  /* background: linear-gradient(45deg, #0060aa, #afe7fc); */
}

.bg-c-green {
  background: linear-gradient(45deg, #2ed8b6, #59e0c5);
}

.bg-c-yellow {
  background: linear-gradient(45deg, #b98600, #fff946);
  /* background: linear-gradient(45deg, #d59a00, #fffb7a); */
}

.bg-c-pink {
  background: linear-gradient(45deg, #500000db, #ff6e6e);
  /* background: linear-gradient(45deg, #a10000db, #ff7f7f); */
}

.dash-card .card {
  height: 160px;
  border-radius: 10px !important;
  -webkit-box-shadow: 0 1px 2.94px 0.06px rgba(4, 26, 55, 0.16);
  box-shadow: 19px 18px 20px 19px rgb(0 0 0 / 2%),
    7px 7px 13px 0px rgb(0 0 0 / 22%);
  border: none;
  margin-bottom: 30px;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.dash-card .card:hover {
  box-shadow: 4px 4px 20px #dadada;
  transition: 0.3s linear all;
}

.dash-card .card .card-block {
  padding: 25px;
}

.dash-card h2 {
  letter-spacing: 2px;
}

.dash-card h3,
.dash-card p {
  letter-spacing: 1.5px;
}

.order-card i {
  font-size: 26px;
}

.f-left {
  float: left;
}

.f-right {
  float: right;
}

/***/

.dashboard .bg-warning {
  background-color: #ffbc58 !important;
  color: #ffffff;
}

.dashboard .bg-danger {
  /* background-color: #ff8084 !important; */
  background-color: #0270c4 !important;
  color: #ffffff;
}

.dashboard .bg-info {
  background-color: #13c9ca !important;
  color: #ffffff;
}

.dashboard .bg-secondary {
  background-color: #29f269 !important;
  /* background-color: #a5a5a5 !important; */
  color: #ffffff;
}

.dashboard .card-icon-cat {
  margin: 5px 0 0 15px;
  font-size: x-large;
  color: #ffbc58;
  background: #f8f9fa;
  padding: 15px;
  border-radius: 35px;
}

.dashboard .card-icon-subcat {
  margin: 5px 0 0 15px;
  font-size: x-large;
  color: #13c9ca;
  background: #f8f9fa;
  padding: 15px;
  border-radius: 35px;
}

.dashboard .card-icon-prod {
  margin: 5px 0 0 15px;
  font-size: x-large;
  /* color: #ff8084; */
  color: #0270c4;
  background: #f8f9fa;
  padding: 15px;
  border-radius: 35px;
}

.dashboard .card-icon-user {
  margin: 5px 0 0 15px;
  font-size: x-large;
  color: #29f269;
  /* color: #a5a5a5; */
  background: #f8f9fa;
  padding: 15px;
  border-radius: 35px;
}

/************CARD LINE DETAILS GRAPH @2nd ROW************/
.card-line-details p {
  font-size: 13px;
  line-height: 1.7;
  letter-spacing: 0.7px;
}

.card-line-details .card {
  border: 0px;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  letter-spacing: 0.5px;
  border-radius: 8px;
  -webkit-box-shadow: 0px 0px 20px 20px rgb(0 0 0 / 2%),
    5px 5px 20px 0px rgb(0 0 0 / 22%);
  box-shadow: 0px 0px 20px 20px rgb(0 0 0 / 2%),
    5px 5px 20px 0px rgb(0 0 0 / 22%);
}

.card-line-details .card:hover {
  box-shadow: 4px 4px 20px #dadada;
  transition: 0.3s linear all;
}

.card-line-details .stats-line-percent {
  text-align-last: center;
}

.order-graph .card-header {
  background-color: #ffffff;
  border-bottom: none;
  padding: 30px;
  border-bottom: 1px solid #f8f8f9;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.order-graph h6 {
  color: #313131;
  font-weight: 500;
  font-size: 15px;
  margin-bottom: 20px;
}

.order-graph .card-body {
  padding: 30px;
  background-color: red !important;
}

.order-graph .media {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.media .media-body {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.order-graph .media .small-box {
  width: 50px;
  height: 50px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

/********************Categroy****************/
.ant-btn-default:hover {
  color: #fff !important;
  border-color: #5a6169 !important;
  background-color: #5a6169!important;
  
}
.ant-btn-default[disabled]:hover{
  color: #615c5c!important;
}

.ant-btn-primary {
  border-color: #5a6169 !important;
  background: #5a6169 !important;
}

.ant-btn-primary:hover {
  border-color: #5a6169 !important;
  background: #5a6169 !important;
}

/* .ant-table table {
  letter-spacing: 1.5px !important;
  font-size: 15px !important;
}
.ant-table-wrapper{
  box-shadow: 0px 0px 0px 0px rgb(0 0 0 / 2%), 2px 5px 15px 0px rgb(0 0 0 / 18%) !important;
  /* border-radius: 10px !important; */
/* } */ 


.progress-bar {
  background: white;
  padding: 10px 30px 10px 10px;
}

.category-list .card {
  -webkit-box-shadow: 0px 0px 0px 0px rgb(0 0 0 / 2%),
    2px 5px 15px 0px rgb(0 0 0 / 22%);
  box-shadow: 0px 0px 0px 0px rgb(0 0 0 / 2%), 2px 5px 15px 0px rgb(0 0 0 / 18%);
}

.category-list .card:hover {
  box-shadow: 2px 2px 10px #dadada;
  transition: 0.3s linear all;
}

.ant-table {
  overflow: auto !important;
  border-radius: 8px !important;
  border-color: #3D5170;
  height: auto !important;
} 

/* .ant-table-container {
  text-align-last: center !important;
}

/****************subCategory*****************/

.subCategory-admin .card {
  margin-right: 0px;
  margin-left: 35px;
  /* margin-right: 20px;
  margin-left: 11px; */
  width: 240px;
  margin-bottom: 30px;
  border: 0px;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  letter-spacing: 0.5px;
  border-radius: 8px;
  -webkit-box-shadow: 0px 0px 20px 20px rgb(0 0 0 / 2%),
    2px 5px 20px 0px rgb(0 0 0 / 22%);
  box-shadow: 0px 0px 20px 20px rgb(0 0 0 / 2%),
    2px 5px 20px 0px rgb(0 0 0 / 22%);
}

.subCategory-admin .card:hover {
  box-shadow: 4px 4px 20px #dadada;
  transition: 0.3s linear all;
}

.subCategory-admin .card .card-body {
  padding: 30px;
  background-color: transparent;
}

.subCategory-admin .card .card-body-deactive {
  padding: 30px;
  background-color: transparent;
  opacity: 0.75;
}

.subCategory-box {
  position: relative;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
  vertical-align: middle;
}

.subCategory-box .img-wrapper,
.img-wrapper {
  position: relative;
  overflow: hidden;
  z-index: 0;
}

.subCategory-box .img-wrapper .front,
.img-wrapper .front {
  opacity: 1;
  top: 0;
  left: 0;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

.image-subCategory {
  background-size: cover;
  background-position: center center;
  display: block;
}

.subCategory-admin .subCategory-box .front:hover .subCategory-hover {
  opacity: 1;
  border-radius: 0%;
  -webkit-transform: scale(1);
  transform: scale(1);
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.subCategory-admin .subCategory-box .front .subCategory-hover {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.7);
  opacity: 0;
  border-radius: 100%;
  -webkit-transform: scale(0);
  transform: scale(0);
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.subCategory-box .img-wrapper .front,
.img-wrapper .front {
  opacity: 1;
  top: 0;
  left: 0;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

.subCategory-admin .subCategory-box .front .subCategory-hover ul {
  padding-left: 0px;
  list-style-type: none;
  margin-bottom: 0;
  padding-right: 0px;
}

.subCategory-admin .subCategory-box .front .subCategory-hover ul li {
  display: inline-block;
  -webkit-box-shadow: 1px 1px 2px 1px rgb(0 0 0 / 8%);
  box-shadow: 1px 1px 2px 1px rgb(0 0 0 / 8%);
  padding: 9px 14px;
  background-color: #ffffff;
  font-size: 18px;
  border-radius: 100%;
  line-height: 1.6;
  height: 45px;
  width: 45px;
  margin: 0 3px;
}

.subCategory-admin .subCategory-box .front .subCategory-hover ul li .btn {
  padding: 0;
  background-color: transparent;
}

.subCategory-admin .subCategory-box .front .subCategory-hover ul li .btn i {
  font-size: 16px;
}

.subCategory-box .subCategory-detail,
.subCategory-box .product-info,
.subCategory-detail {
  padding-left: 5px;
  margin-top: 15px;
}

.subCategory-box .subCategory-detail .heading {
  transition: 0.5s ease;
  text-decoration: none;
}

.subCategory-box .subCategory-detail .heading:hover {
  color: #0060aa;
  cursor: pointer;
}

.subCategory-box .subCategory-detail h6,
.subCategory-box h6,
.subCategory-detail h6 {
  font-weight: 550;
  color: #444444;
  line-height: 1;
  margin-bottom: 0;
  padding-top: 2px;
  padding-bottom: 5px;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
  font-size: 16px;
  text-transform: uppercase;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.subCategory-detail p {
  font-weight: 300;
  color: #777777;
  line-height: 1;
  margin-bottom: 0;
  padding-top: 2px;
  padding-bottom: 5px;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
  font-size: 13px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.subCategory-detail h4 {
  font-size: 16px;
  color: #222222;
  font-weight: 500;
  margin-bottom: 0;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

.subCategory-detail .anticon svg {
  margin-top: -4px !important;
}

.subCategory-detail .ant-switch-checked {
  background-color: #0bad0b !important;
}

.subCategory-dashboard .ant-pagination-options {
  display: none !important;
}

.subCategory-dashboard .ant-pagination-item-active {
  border-color: #014d87 !important;
}

.subCategory-dashboard .ant-pagination-item:hover {
  border-color: #014d87 !important;
}

.ant-pagination-prev:hover .ant-pagination-item-link,
.ant-pagination-next:hover .ant-pagination-item-link {
  color: #014d87;
  border-color: #014d87 !important;
}

.ant-pagination-jump-next
  .ant-pagination-item-container
  .ant-pagination-item-link-icon {
  color: #014d87 !important;
}

.ant-pagination-item-link .anticon svg {
  margin: -6px 0 0 0;
}

.btn-admin {
  font-size: 14px;
  border-radius: 5px;
  letter-spacing: 1px;
  line-height: 20px;
  /* text-transform: uppercase; */
  font-weight: 550;
  -webkit-transition: 0.3s ease-in-out;
}

.subCategory-dashboard .btn-subCategory {
  display: inline-block;
  background: #014d87 !important;
  color: #fff;
  border: 1px #014d87;
  width: 190px !important;
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  -o-border-radius: 5px;
  -ms-border-radius: 5px;
  cursor: pointer;
  letter-spacing: 2px;
}

.subCategory-dashboard .btn-subCategory:hover {
  background: #0060aa !important;
}

.ant-modal-header {
  /* font-family: "Poppins" !important; */
}

.ok-modal {
  background: #0060aa !important;
  border: 1px #0060aa !important;
  font-size: 14px;
  border-radius: 5px;
  letter-spacing: 1.5px !important;
  line-height: 50px;
 
  font-weight: 550;
}

.ok-modal:hover {
  background: #016ec2 !important;
}

.close-modal {
  background-color: #fcaf3d !important;
  border-color: #fcaf3d !important;
  color: white !important;
  font-size: 14px;
  border-radius: 5px;
  letter-spacing: 1.5px !important;
  line-height: 20px;
  /* text-transform: uppercase; */
  font-weight: 550;
}

.close-modal:hover {
  background-color: #ffbc58 !important;
  border-color: #ffbc58 !important;
  color: white !important;
}

.ant-modal-footer {
  /* border-top: none !important; */
}
.ant-modal-body {
  margin: 0px 0px 25px 0 !important;
}
.ant-input:focus{
  border-color: black !important;
    box-shadow: 0 0 0 2px rgba(24,144,255,.2);
    border-right-width: 1px;
}
.ant-upload.ant-upload-select-picture-card,
.ant-upload-list-picture-card-container {
  width: 475px !important;
}

/* .subCategory-dashboard input[type="text"] {
  margin: 10px 0 0 30px;
  width: 0px;
  box-sizing: border-box;
  border: 2px solid #f1c8c8;
  border-radius: 35px;
  font-size: 16px;
  background-color: white;
  background-position: 10px 10px;
  background-repeat: no-repeat;
  padding: 12px 20px 12px 40px;
  transition: width 0.4s ease-in-out;
}
.subCategory-dashboard input[type="text"]:focus {
  width: 100%;
} */
.subCategory-dashboard .circle-active {
  fill: #28a745;
  stroke: #28a745;
  /* stroke: #0f1c3f; */
  stroke-width: 0.1875em;
}

.subCategory-dashboard .circle-inactive {
  fill: #dc3545;
  stroke: #dc3545;
  /* stroke: #0f1c3f; */
  stroke-width: 0.1875em;
}

.ant-progress-bg {
  background-color: #e75362 !important;
}

/***********add admin***************/

.add-admin .card {
  -webkit-box-shadow: 0px 0px 0px 0px rgb(0 0 0 / 2%),
    2px 5px 15px 0px rgb(0 0 0 / 22%);
  box-shadow: 0px 0px 0px 0px rgb(0 0 0 / 2%), 2px 5px 15px 0px rgb(0 0 0 / 18%);
}

.add-admin .card:hover {
  box-shadow: 2px 2px 10px #dadada;
  transition: 0.3s linear all;
}

.add-admin-button {
  display: inline-block;
  background: #fe7865 !important;
  color: #fff;
  border-bottom: none;
  width: 175px !important;
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  -o-border-radius: 5px;
  -ms-border-radius: 5px;
  cursor: pointer;
}

.add-admin-button:hover {
  background: #ff5b45 !important;
}

/****************INput antd***************/
.ant-select:not(.ant-select-disabled):hover .ant-select-selector {
  border-color: #0276ce !important;
}

.ant-input {
  height: 32.5px !important;
  letter-spacing: 1.5px !important;
}

.ant-modal-title,
.ant-select {
  letter-spacing: 1.5px !important;
}

.ant-input:focus,
.ant-input-focused {
  /* border-color: #0276ce !important; */
  border-color: #000000 !important;
  box-shadow: 0 0 0 1px rgb(59, 60, 61) !important;
}

.ant-input:hover {
  /* border-color: #0276ce !important; */
  border-color: #000000 !important;
}
/*******Title**********/
.page-title{
  font-size: 2.640rem;
    font-weight: 500;
    line-height: 1;
    margin: 0;
    padding: 0;
    color: #3d5170;
}
/****************INput antd end***************/
/******ADD Purchase*****/
.add-purchase .ant-spin-spinning {
  display: list-item !important;
  margin: 225px 0 0 0 !important;
}

.add-purchase .ant-picker {
  width: -webkit-fill-available !important;
}

.add-purchase .save-purchase {
  width: -webkit-fill-available;
}

.add-purchase .card {
  -webkit-box-shadow: 0px 0px 20px 20px rgb(0 0 0 / 2%),
    2px 5px 20px 0px rgb(0 0 0 / 22%);
  box-shadow: 0px 0px 20px 20px rgb(0 0 0 / 2%),
    2px 5px 20px 0px rgb(0 0 0 / 22%);
}

.add-purchase .card:hover {
  box-shadow: 4px 4px 20px #dadada !important;
  transition: 0.12s linear all;
}

.ant-btn-dashed:hover,
.ant-btn-dashed:focus {
  color: #007bff !important;
  border-color: #007bff !important;
}

.ant-btn-dangerous {
  color: #0060aa !important;
  border-color: #0060aa !important;
}

.add-purchase .ant-btn-dangerous:hover {
  color: #0276ce !important;
  border-color: #0276ce !important;
}

.ant-select-focused:not(.ant-select-disabled).ant-select:not(
    .ant-select-customize-input
  )
  .ant-select-selector {
  border-color: #0276ce !important;
  box-shadow: 0 0 0 2px rgb(23, 158, 237) !important;
}

.purchase-list .ant-table-content {
  max-height: 300px !important;
}

.final-purchases-list .ant-table-body {
  max-height: 55vh !important;
}

.list-accounts .ant-table-content {
  max-height: 47vh !important;
}

.table-row-dark {
  background-color: #ffcc81 !important;
}

/******Barcode**********/
.barcode-card {
  width: 50%;
  height: 410px;
  overflow-y: auto;
  margin: 2% 0 0 25%;
}

.ant-btn .anticon.anticon-plus > svg,
.ant-btn .anticon.anticon-stop > svg {
  margin-top: -5px !important;
}
.ant-btn-default{
  /* text-shadow: 0 -1px 0 rgba(0,0,0,.12) !important; */
    box-shadow: 0 2px 0 rgba(0,0,0,.045) !important;
    border-color: #5a6169 !important;
    color: #5a6169 !important;

}




.ant-picker:hover {
  border-color: #0276ce !important;
}

/* .ant-switch-checked {
  background-color: #0060aa !important;
} */
.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #014d87 !important;
  border-color: #014d87 !important;
}

/***********Search bar**********/
.header-right-wrap .same-style button {
  height: 32px !important;
}

.search-content .anticon svg {
  margin: -11px 0 0 0 !important;
}

.ant-input-feedback-icon {
  margin: -6px 0 0 0 !important;
}

.ant-message .anticon {
  top: -2px !important;
}

/*************************SCROLLBAR Starts*************************/

/* width */
::-webkit-scrollbar {
  width: 5px;
  height: 7px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #9fa0a1;
  border-radius: 8px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #666666;
}

/*************************SCROLLBAR ENDS*************************/

.searchCss {
  background-color: white;
  color: black;
  margin-top: 0;
}

.searchCss a {
  color: black;
  margin-bottom: 2px;
  text-decoration: none;
}

/********************Media Responsive START********************/
@media (min-width: 1501px) {
  .trigger-phone {
    display: none !important;
  }

  .ant-drawer-body {
    flex: 1 1;
    min-width: 0;
    min-height: 0;
    padding: 0px !important;
    overflow: auto;
  }
}

/* Normal desktop :1200px. */
@media (min-width: 1200px) and (max-width: 1500px) {
  .trigger-phone {
    display: none !important;
  }

  .ant-drawer-body {
    flex: 1 1;
    min-width: 0;
    min-height: 0;
    padding: 0px !important;
    overflow: auto;
  }
}

/* Normal desktop :992px. */
@media (min-width: 992px) and (max-width: 1199.98px) {
  .trigger-phone {
    display: none !important;
  }

  .ant-drawer-body {
    flex: 1 1;
    min-width: 0;
    min-height: 0;
    padding: 0px !important;
    overflow: auto;
  }
}

/* Tablet desktop :768px. */
@media (min-width: 768px) and (max-width: 991.98px) {
  .trigger-phone {
    display: none !important;
  }

  .ant-drawer-body {
    flex: 1 1;
    min-width: 0;
    min-height: 0;
    padding: 0px !important;
    overflow: auto;
  }
}

/* small mobile :320px. */
@media (min-width: 576px) and (max-width: 767.98px) {
  .barcode-card {
    width: 100%;
    margin: 2% 0 0 0%;
  }

  .ant-breadcrumb {
    float: none;
    margin: 10px 0 0 20px !important;
  }

  .add-button {
    float: none !important;
    margin: 0 0 0 0px;
  }

  /* .recharts-surface {
    width: 200px !important;
  } */
  .card-line-details .stats-line-percent {
    text-align-last: left;
  }

  .ant-layout-sider {
    display: none;
  }

  .trigger {
    display: none !important;
  }

  .trigger-phone {
    /* padding: 0 24px;
    font-size: 18px;
    line-height: 64px;
    cursor: pointer;
    transition: color 0.3s;
    color: #014d87 !important; */
    transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms !important;
    color: rgba(52, 49, 76, 1) !important;
    padding: 15px;
    margin: 0 0 0 10px;
    font-size: 18px;
    line-height: 64px;
    cursor: pointer;
  }

  .trigger-phone:hover {
    background-color: rgba(0, 0, 0, 0.04);
    color: rgba(52, 49, 76, 1) !important;
    border-radius: 50% !important;
    overflow: visible !important;
  }

  .ant-drawer-body {
    flex: 1 1;
    min-width: 0;
    min-height: 0;
    padding: 0px !important;
    overflow: auto;
  }

  .ant-drawer-body {
    /* padding: 10px 10px 10px 20px !important; */
    padding: 0px !important;
    top: 0px;
    left: 0px;
    z-index: 111;
    /* height: 100vh; */
    /* position: fixed !important; */
    overflow: hidden !important;
    background-size: cover !important;
    background-position: center top !important;
    box-shadow: rgb(0 0 0 / 6%) 0px 5px 5px -3px,
      rgb(0 0 0 / 4%) 0px 8px 10px 1px, rgb(0 0 0 / 4%) 0px 3px 14px 2px !important;
    background-repeat: no-repeat !important;
    color: rgb(255, 255, 255) !important;
    transition: all 250ms ease-in-out 0s !important;
    background-image: linear-gradient(
        rgba(34, 42, 69, 0.96),
        rgba(34, 42, 69, 0.96)
      ),
    
  }

  .ant-upload.ant-upload-select-picture-card {
    width: 310px !important;
  }

  .pagination-div {
    float: none;
    margin: 0 0 0 23px;
  }

  .pages-header {
    margin: 0 0 10px 0px;
  }

  .progress-bar {
    margin: 0 0 0 20px;
  }

  .Search-admin {
    margin: 0 0 0 20px;
  }

  .ant-drawer-left.ant-drawer-open .ant-drawer-content-wrapper {
    width: 301px !important;
  }

  .ant-layout-content {
    margin: 20px 0px !important;
    padding: 10px 5px !important;
    background-color: #a3adb7 !important;
  }
}

/* Large Mobile :480px. */
@media only screen and (max-width: 575.98px) {
  .barcode-card {
    width: 100%;
    margin: 2% 0 0 0%;
  }

  .ant-breadcrumb {
    float: none;
    margin: 10px 0 0 20px !important;
  }

  .add-button {
    float: none !important;
    margin: 0 0 0 0px;
  }

  /* .recharts-surface {
    width: 200px !important;
  } */
  .card-line-details .stats-line-percent {
    text-align-last: left;
  }

  .ant-layout-sider {
    display: none;
  }

  .trigger {
    display: none !important;
  }

  .trigger-phone {
    transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms !important;
    color: rgba(52, 49, 76, 1) !important;
    padding: 15px;
    margin: 0 0 0 10px;
    font-size: 18px;
    line-height: 64px;
    cursor: pointer;
  }

  .trigger-phone:hover {
    background-color: rgba(0, 0, 0, 0.04);
    color: rgba(52, 49, 76, 1) !important;
    border-radius: 50% !important;
    overflow: visible !important;
  }

  .ant-drawer-body {
    flex: 1 1;
    min-width: 0;
    min-height: 0;
    padding: 0px !important;
    overflow: auto;
  }

  .ant-drawer-body {
    /* padding: 10px 10px 10px 20px !important; */
    top: 0px;
    left: 0px;
    z-index: 111;
    /* height: 100vh; */
    /* position: fixed !important; */
    overflow: hidden !important;
    background-size: cover !important;
    background-position: center top !important;
    box-shadow: rgb(0 0 0 / 6%) 0px 5px 5px -3px,
      rgb(0 0 0 / 4%) 0px 8px 10px 1px, rgb(0 0 0 / 4%) 0px 3px 14px 2px !important;
    background-repeat: no-repeat !important;
    color: rgb(255, 255, 255) !important;
    transition: all 250ms ease-in-out 0s !important;
    background-image: linear-gradient(
        rgba(34, 42, 69, 0.96),
        rgba(34, 42, 69, 0.96)
      ),
      
  }

  .ant-upload.ant-upload-select-picture-card {
    width: 310px !important;
  }

  .pagination-div {
    float: none;
    margin: 0 0 0 23px;
  }

  .pages-header {
    margin: 0 0 10px 0px;
  }

  .progress-bar {
    margin: 0 0 0 20px;
  }

  .Search-admin {
    margin: 0 0 0 20px;
  }

  .ant-drawer-left.ant-drawer-open .ant-drawer-content-wrapper {
    width: 301px !important;
  }

 
}

/********************Media Responsive END********************/
.head-row {
  display: flex !important;
}

.item-head {
  margin: 0 35px 0 0 !important;
}

.item-details {
  margin: 0 50px 0 0 !important;
}

.item-markings {
  margin: 0 135px 0 0 !important;
}

.item-others {
  margin: 0 225px 0 0 !important;
}




/***********neww UI **************/


.ant-menu {
  background: transparent !important;
  /* color: rgb(255, 255, 255) !important; */
  color: rgba(44, 56, 74, 0.681) !important;
  overflow-y: auto !important;
  height: 100vh !important;
}
.ant-menu-item-selected {
  /* background: rgba(255, 255, 255, 0.16) !important; */
  background: none !important;
  color: #007bff !important;
}
.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected:hover {
  /* background: #007bff !important; */
  /* background: rgba(255, 255, 255, 0.08) !important; */
}
.ant-menu-item-selected a {
  color: #007bff !important;
}
.ant-menu-item-selected a:hover,
.ant-menu-item-selected:hover,
.ant-menu-light .ant-menu-item:hover,
.ant-menu-item a:hover {
  color: #007bff !important;
}
.ant-menu-item:hover,
.ant-menu-item-active,
.ant-menu-submenu-open,
.ant-menu-submenu-active,
.ant-menu-submenu-selected,
.ant-menu-submenu-arrow,
.ant-menu-submenu-title:hover {
  /* background: #007bff !important; */
}
.ant-menu-inline .ant-menu-item-selected::after {
  border-right: none !important;
}
.ant-menu-inline,
.ant-menu-vertical {
  border-right: none !important;
}
.sider-links:hover {
  color: rgb(255, 255, 255) !important;
  text-decoration: none;
}
/* .ant-menu-item a {
  color: rgba(44, 56, 74, 0.681) !important;
} */
.ant-menu-inline.ant-menu-root .ant-menu-item,
.ant-menu-inline.ant-menu-root .ant-menu-submenu-title {
  /* letter-spacing: 1.5px !important; */
  margin: 0 0 0 15px !important;
  width: 227.5px !important;
  /* display: flex;
  overflow: hidden; */
  border-radius: 4px !important;
  height: 44px !important;
  white-space: pre !important;
  margin-bottom: 8px !important;
  text-decoration: none !important;
  -webkit-box-pack: justify !important;
  justify-content: space-between !important;
  transition: all 150ms ease-in 0s !important;
  /* color: rgb(255, 255, 255) !important; */
}



.ant-menu-item-selected .ant-menu-item-icon{
  color: #007bff !important;
}



.card {
  border-radius: 10px !important;
  margin-left: -4rem !important;
  margin-right: -4rem !important;
  /* background-color: red !important; */
}

.detail-card{
  margin-left: -1.0rem !important;
  margin-right: -1.0rem !important;
}


.ant-table-wrapper {

  /* border-radius: 12px !important; */
  /* background-color: #e9ebed !important; */
}

.ant-table-container{
  /* background-color: #e9ebed !important; */
}
.ant-btn[disabled] {
  /* opacity: 0.6 !important; */
  background-color: transparent !important;
  border-color: #999 !important;
 
}
.underline-effect:hover{
  text-decoration: underline;
  color: #007bff;
  cursor: pointer;
}
.underline-effect{
  color: #007bff !important
}

.qna_form{
  margin-top: 1% !important;
  margin-left: 1% !important;
  margin-right: 1% !important;
 
}
.question-box{
  height: 55vh !important;
  overflow-y: scroll !important;
  overflow-x: hidden !important;
} 
.ant-input[disabled]{
  color: #615c5c !important;
}

.btn-for-icon{
  background: none !important;
  padding: 0 !important;
  border: none !important;
}
.btn-for-icon:hover{
  background: none !important;
  padding: 0 !important;
  border: none !important;
}





